.acceptInvitationContainer {
  margin-top: 20px;
  width: 550px;
  background: white;
  height: 280px;
}

.acceptInvitationContainerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.acceptInvitationContainer {
  padding: 20px;
}

.acceptInvitationContainerHeader {
  margin-bottom: 20px;
}
