html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white !important;
  background: #e4e4e4;
  flex: 1;
}

html {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}

.errorContainer {
  padding-top: 10px;
  flex: 1;
}

.App {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: black;
  height: 100%;
  background: #e4e4e4;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.containerBox {
  box-shadow: 0 0 5px grey;
  background-color: white;
  border: 1px solid grey;
  padding: 5px;
}

.dataContainerRowField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  text-align: right;
}

.dataContainerRowValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  flex: 3;
}

.dataContainerIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.center {
  text-align: center;
  flex-grow: 1;
}

.button {
  border: 1px solid grey;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  color: #000;
  margin: 5px;
}

.button:hover {
  cursor: pointer;
  background: #d3d3d3;
  text-decoration: none;
}
